import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
      {
        label: 'Bridge',
        href: 'https://owlto.finance/bridge',
      },
    ],
  },
  {
    label: 'NFT',
    icon: 'InfoIcon',
    href: 'https://www.blastcat.art',
  },
  {
    label: 'Tool',
    icon: 'LanguageIcon',
    href: 'https://dexscreener.com',
  },
]

export default config
